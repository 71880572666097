/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"
import Panel from "../components/panel/panel"
import Layout from "../components/layout/layout"
import Metadata from "../components/metadata/metadata"
import { ButtonLink } from "../components/button/button"

export default function Careers() {
  return (
    <Layout headerbg="bgGray">
      <Metadata
        title="We’re hiring"
        description="We hire the best. We emphasize collaboration and transparent
        communication to improve creativity and effectiveness. The team is
        supportive, engaging, interactive, and of course fascinated by
        virtual reality."
        //image={buildSocialImage(socialImage)}
      />
      <Panel
        sx={{
          bg: "bgGray",
          py: ["3rem", "6rem", "8rem"],
        }}
      >
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 5"],
            pt: ["3rem", "unset"],
            fontSize: ["1.5rem", "1.6rem", "2rem"],
            lineHeight: [1.47, 1.5, 1.6],
          }}
        >
          <Themed.h1
            className="scaleText"
            sx={{
              "--max-font-size": [2.6, 3.8, 5.8],
              mb: ["2.8rem", "6rem", "8rem"],
            }}
          >
            We’re <em>hiring.</em>
          </Themed.h1>
          <Themed.p>
            We hire the best. We emphasize collaboration and transparent
            communication to improve creativity and effectiveness. The team is
            supportive, engaging, interactive, and of course fascinated by
            virtual reality.
          </Themed.p>
          <Themed.p>All positions are remote unless otherwise stated.</Themed.p>
          <ButtonLink
            to="https://www.linkedin.com/company/moth-flame-vr/jobs/"
            target="_blank"
          >
            View open roles
          </ButtonLink>
        </div>
        <div
          sx={{
            gridColumnStart: [2, 6, 7],
            gridColumnEnd: ["span 2", "span 3", "span 5"],
            mt: ["2rem"],
            transform: [
              "translateY(3rem)",
              "translateY(10rem)",
              "translateY(8rem)",
            ],
            lineHeight: 1,
            display: "inline-flex",
            justifyContent: "center",
          }}
        >
          <StaticImage
            placeholder="none"
            src="../images/mask-facing-upward.png"
            alt="VR mask"
            objectFit="scale-down"
          />
        </div>
      </Panel>
    </Layout>
  )
}
